import React from 'react';

const Workflows = () => {
  return (
    <div className="workflows-page">
      <h1>Workflows</h1>
      <p>This is the Workflows page. Content coming soon!</p>
    </div>
  );
};

export default Workflows;
