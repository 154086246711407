import React from 'react';

const Main = () => {
    return (
      <main className="content">
        <h1>
        Unleash your creativity with ComfyUI
        </h1>
        <p>
          Free to test and download workflow by click the Save button below in the ComfyUI.
        </p>
        <div className="iframe-container">
          <iframe
            //src="https://comfyui.runcomfy.com/?workflow=runcomfy-comfyui-web&force-load=true"
            //src="https://comfyui.array.wang"
            src="https://comfyui.explorecomfy.com"
            title="ComfyUI Interface"
            width="2000"
            height="720"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </main>
    );
};

export default Main;