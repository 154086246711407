import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import './App.css';
import Main from './Main';
import Workflows from './Workflows'

import logoImage from './static/images/logo.png'

const App = () => {
  return (
    <Router>
      <div>
        <Header />
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/workflows" element={<Workflows />} />
        </Routes>
      </div>
    </Router>
  );
};

const Header = () => {
  return (
    <header className="header">
      <Link to="/" className="logo">
        <img 
          src={logoImage}
          alt="FreeComfy Logo" 
          width="30" 
          height="30"
        />
        <p>FreeComfy</p>
      </Link>
      {/*
      <nav className="nav-links">
        <Link to="/workflows">Workflows</Link>
        <a href="#">Tutorials</a>
        <a href="#">Nodes</a>
        <a href="#">Pricing</a>
      </nav>
      <div className="right-nav">
        <a href="#" className="sign-in">Sign In</a>
      </div>
      */}
    </header>
  );
};

export default App;
